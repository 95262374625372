@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .search-input{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 56.4%, rgba(153, 153, 153, 0.04) 100%);
  
  width: 100%;
  height: 64px;
  color: #ffffff;
  border: 1px solid #FFF;
  padding:10px;
text-align: center;  


} */
/* li {
  margin-bottom: 16px;
  color: #fff;
  font-weight: 400;
  font-family: Poppins;
  font-size: 1rem;
} */
.th-products {
  margin-bottom: 12px;
  color: #fff;
  font-weight: 400;
  font-family: Poppins;
}
.th-products-header {
  margin-bottom: 16px;
  color: #fff;
  font-weight: bold;
  font-family: Poppins;
}
.th-products-desc {
  margin-bottom: 12px;
  color: #fff;
  font-weight: 400;
  font-family: Poppins;
  /* width:100vw; */
}
.table-data-products {
  color: #fff;
  font-size: 18px;
  font-family: Poppins;
}
.slide-in-left {
  animation: slideInLeft 5s ease;
}

.slide-in-right {
  animation: slideInRight 5s ease;
}

@keyframes slideInLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
/* ResponsibleLending.css */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.resLending {
  opacity: 0;
  transition: opacity 0.25s, transform 2s;
}

.resLending.animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  animation: fadeInUp 1s forwards;
}

.hero-heading {
  margin: 32px 0;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-top: -80px;
  font-family: Poppins;
}
.hero-sub {
  margin: 32px 0;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin-top: -40px;
  font-family: Poppins;
  margin-top: 120px;
}

.App {
  overflow: hidden;
}

.row {
  justify-content: space-evenly;
}

.App {
  background: rgb(31, 32, 37);
  background: linear-gradient(
    90deg,
    rgba(31, 32, 37, 1) 0%,
    rgba(2, 0, 36, 1) 35%,
    rgba(7, 68, 100, 1) 100%
  );
}

.page-container {
  padding: 0 88px 110px;

  &.products-page {
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 40px;
  }

  &.responsible-lending-page {
    padding-right: 136px;
    padding-left: 136px;
    padding-top: 115px;
  }

  &.contact-us {
    padding-top: 80px;
    padding-right: 200px;
    padding-left: 200px;
  }

  &.about-us {
    padding-top: 80px;
    padding-right: 80px;
    padding-left: 80px;
  }

  &.lending-partners {
    padding-top: 87px;
    padding-right: 3%;
    padding-left: 3%;
  }

  &.faq {
    padding-top: 96px;
    /* padding-right: 336px;
    padding-left: 336px; */
  }
}

.b {
  margin-top: 20px;
}

/* hero section starts */

.home-download {
  display: none;
}
.home-apply {
  width: 30%;
}

.brand-secondary {
  margin: 35px;
}

.hero-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 160px;
}

.hero-section img {
  margin-top: 70px;
  height: 400px;
  position: absolute;
  align-items: right;
  right: 20px;
  top: 40px;
}
@keyframes vertical-shake {
  0%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

.img-home {
  animation: vertical-shake 4s infinite;
}

.hero-section .content {
  width: 70%;
}

.hero-section .content h6 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: normal;
  font-family: Poppins;
  /* font-family: "General Sans Variable"; */
}

.hero-section .content p {
  /* color: rgba(255, 255, 255, 0.6); */
  color: #dee2e6;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
  /* width: 700px; */
  font-family: Poppins;
}

/* hero section ends */

/* application-process-section starts */
.halfcard {
  padding: 15px;
}

.application-process-card {
  /* margin: 0px; */
  display: flex;
  justify-content: space-between;
  padding: 10px 0 20px 80px;
  border-radius: 24px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
  color: #ffffff;

  .content {
    width: 100%;

    .list-number {
      display: flex;
      margin-top: 24px;
      height: 46px;
      width: 46px;
      border-radius: 13px;
      background: #0072ad;
      line-height: 28px;
      align-items: center;
      justify-content: center;

      /* it gets ruined in prod so switching to light-overlay-number */
      span {
        width: 36px;
        height: 36px;
        display: flex;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 40px;
        font-size: 24px;
        justify-content: center;
        align-items: center;
      }

      .light-overlay-number {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 40px;
        font-size: 24px;
        padding-left: 25%;
        padding-right: 25%;
        padding-top: 10%;
        padding-bottom: 10%;
        width: 36px;
        height: 36px;
      }
    }

    h6 {
      margin-top: 30px;
      text-align: left;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
      font-family: Poppins;
    }

    p {
      text-align: left;
      font-size: 18px;
      margin-bottom: 0px;
      font-weight: 300;
      font-family: Poppins;
    }
  }

  & + .application-process-card {
    margin-top: 64px;
  }

  &.small-card {
    padding: 0 0 0 80px;
    margin-bottom: 32px;

    h6 {
      margin-top: 40px;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 32px;
    }

    &.half-card {
      padding-top: 0px;
      padding-bottom: 54px;
      padding-right: 40px;
      display: flex;
      align-items: end;

      .content {
        width: calc(100% - 104px + 24px);
        margin-right: 24px;
      }
    }
  }
}

.application-process-section {
  .heading {
    margin: 32px 0;
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-top: -40px;
    font-family: Poppins;

    &.extra-margin-top {
      margin-top: 160px;
      font-family: Poppins;
      font-size: 36px;
    }
  }
}

/* application-process-section ends */

/* user-feedback-section starts */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.user-feedback-section {
  margin-top: 160px;

  .section-heading {
    margin-bottom: 48px;
    margin-top: -80px;
    font-family: Poppins;
  }

  .feedback-card-container {
    display: flex;
    grid-gap: 32px;
    gap: 32px;
    align-items: center;
    justify-content: center;

    .each-feedback-card {
      padding: 10px;
      border-radius: 30px;
      /* display: flex; */
      align-items: center;
      background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
      /* padding: 34px 64px; */

      .card-body {
        padding: 0;
        text-align: center;

        img {
          margin-bottom: 16px;
        }

        .feedback-ratings {
          height: 34px;
          margin-bottom: 22px;
        }

        .comment {
          color: #fff;
          font-size: 16px;
          margin-bottom: 48px;
        }

        .user-name {
          color: rgba(255, 255, 255, 0.6);
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
  }
}
.text-center {
  margin-top: 40px;
  width: 300px;
}

.animate-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.animate-right {
  animation: slideInFromRight 1s ease-out forwards;
}

.animate-shake {
  animation: shake 0.5s ease-in-out 1s forwards;
}

/* user-feedback-section ends */

/* products-hero-section starts */
.products-hero-section,
.responsible-lending-section,
.contact-us-section,
.faq-section {
  h6,
  .heading {
    background: linear-gradient(
      91deg,
      #1b9bdc -2.45%,
      #76d1ff 51.77%,
      #1b9bdc 97.01%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    font-family: Poppins;
    margin: 50px;
  }
}

.uncolored-heading {
  color: white;
  background-color: transparent;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  font-family: Poppins;
  margin: 50px;
}

.products-hero-section {
  padding-bottom: 50px; /* padding-bottom: 230px;  */
  text-align: center;

  small {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 44px;
    display: block;
    font-family: Poppins;
  }

  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
    font-family: Poppins;
    margin-bottom: -50px;
  }

  .btn-container {
    margin-top: 128px;

    .primary-gradient-btn {
      border-radius: 8px;
      border: 1px solid #fff;
      background: var(
        --card-gradient,
        linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%)
      );
      display: flex;
      width: 324px;
      height: 64px;
      padding: 20px;
      align-items: center;
      grid-gap: 16px;
      gap: 16px;
      color: #ffffff;
      font-family: Poppins;
    }
  }
}
.eligibility {
  margin-bottom: -60px;
  margin-top: 50px;
}

/* products-hero-section ends */

/* description-section starts */
.description-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 120px; */
  padding: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .content {
    color: #fff;

    h6 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 44px;
      font-family: Poppins;
    }

    ol {
      font-size: 24px;
      padding-left: 28px;

      li {
        margin-bottom: 16px;
        color: #fff;
        font-weight: 400;
        font-family: Poppins;
        font-size: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* description-section ends */

/* responsible-lending-section starts */
.responsible-lending-section,
.contact-us-section {
  .content {
    p {
      margin-top: 32px;
      color: #fff;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0;
      font-family: Poppins;
    }
  }
}

.responsible-lending-section {
  text-align: center;

  .content {
    margin-bottom: 100px;

    p {
      margin-top: 24px;
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .section-heading {
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 64px;
    text-align: center;
    font-family: Poppins;
  }

  .application-process-card {
    padding: 50px 36px;

    .content {
      margin: 0;
      width: 100%;
      padding-left: 64px;
      position: relative;
      text-align: left;

      p {
        margin-top: 0;
        font-size: 24px;
      }

      .list-number {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0px;
      }
    }

    &.application-process-card {
      margin-top: 40px;
      height: 200px;
      display: flex;
      align-items: center;
    }
  }
}

/* responsible-lending-section ends */

/* contact-us-section starts */
.contact-us-section {
  /* text-align: center; */

  .heading {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 54px;
    font-weight: 700;
    font-family: Poppins;
  }

  .content {
    margin-bottom: 180px;
  }
}

.form-control-dd-options {
  padding: 10px;
  color: #151b1e;
}
.dropdown {
}

.contact-submit-button {
  background-color: #0bb2e6;
  color: white;
  /* padding: 15px 20px; */
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  width: auto;
  margin-left: 38%;
  font-family: Poppins;
}

@media (min-width:768px) {
  .cntUs{
    display: flex;
    padding: 0 58px 110px;
  /* justify-content:safe; */
  }
}

/* contact-us-section ends */

/* about-us-container starts */
.about-us-container {
  .content {
    padding: 0 20px;
    justify-content: center;

    .heading {
      opacity: 0.8;
      color: #1b9bdc;
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 52px;
      font-family: Poppins;
      margin-top: 50px;
    }

    .about-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 120px;

      .about-section-content {
        margin-top: 60px;
        padding-right: 6%;

        h6 {
          color: #fff;
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 12px;
          font-family: Poppins;
        }

        p {
          color: #fff;
          margin-left: 0%;
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 0;
          font-family: Poppins;
        }
      }
    }
  }
  .aboutus {
    padding-top: 6%;
  }
  @media (max-width: 768px) {
    .aboutus {
      margin: 20px;
    }
  }
  .about-points-container {
    margin-bottom: 160px;

    .each-point {
      /* &:nth-child(even) {
        padding-left: 20px;
      } */

      /* &:nth-child(odd) {
        padding-right: 20px;
      } */

      .quote-container {
        padding: 0 16px;
        display: flex;

        span {
          opacity: 0.99;
          background: linear-gradient(91deg, #4cbffc -2.45%, #2199ef 97.01%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 80px;
          font-weight: 700;
        }

        p {
          color: #7ed2ff;
          font-size: 22px;
          font-weight: 700;
          opacity: 0.8;
          margin-bottom: 0;
          padding: 30px 0;
          display: flex;
          align-items: center;
        }
      }

      .application-process-card {
        padding: 22px;
        height: 220px;

        .content {
          width: 100%;
          padding-bottom: 5%;
          padding-left: 0%;
          padding-right: 0%;

          p {
            padding: 0%;
            font-size: 16px;
          }
        }
      }
    }
  }

  .application-process-card {
    align-items: center;
    padding-left: 1%;
    padding-top: 2%;

    h6 {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 8px;
      margin-top: 0;
      font-family: Poppins;
    }

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      font-family: Poppins;
    }
  }
}

/* about-us-container ends */

/* lendingpartners-section starts */
.legend-partners{
    border-radius: 32px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
    padding-bottom: 0.7%;
    border: 1px solid #3caff5;
    /* margin: 50px;
      padding: 150px 50px; */
    
}
.lendingpartners-section {
  .content {
    text-align: center;

    h6.heading {
      background: linear-gradient(90deg, #1b9bdc 0%, #7ed2ff 53%, #1b9bdc 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 36px;
    }

    p.caption {
      color: #fff;
      font-size: 20px;
      margin-bottom: 50px;
    }

    .company-container {
      h6 {
        margin-top: 16px;
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 2.4px;
        margin-bottom: 64px;
      }

      p {
        color: #fff;
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 2px;
        margin-bottom: 80px;
      }

      .website-text {
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 1.6px;
        font-family: Poppins;
      }
    }
  }
}

/* lendingpartners-section ends */

/* faq-section starts */
.faq-section {
  .faq-container {
    margin-top: 96px;
    max-width: 768px;

    .form-group {
      position: relative;
      margin-bottom: 48px;

      span {
        position: absolute;
        left: 24px;
        top: 28px;
      }

      input {
        padding: 24px;
        padding-left: 68px;
        border-radius: 8px;
        border: 1px solid #d3d3d3;
        color: #ffffff;
        font-size: 24px;
        height: 88px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.08) 56.4%,
          rgba(153, 153, 153, 0.04) 100%
        );
        box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.12);

        &::placeholder {
          color: #fff;
        }
      }
    }

    .faq-tab-container {
      .tab-buttons {
        button {
          width: 100%;
          height: 64px;
          color: #ffffff;
          border: 1px solid #fff;
          /* margin: 0px; */

          &.active-btn {
            background: linear-gradient(91deg, #00246a -2.45%, #2199ef 97.01%);
          }
        }
      }
      .answer-section {
        margin-top: 48px;
        width: 100%;

        .each-answer {
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .faq-toggler {
          width: 100%;
          border: 1px solid #ffffff;
          padding: 0;

          .faq-toggler-content {
            display: flex;
            width: 100%;
            padding: 14px;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            text-align: left;
            font-family: Poppins;

            svg {
              &.isopened {
                animation-direction: normal;
                animation-duration: 0.5s;
                animation-iteration-count: 1;
                animation-name: rotateBottomhalf;
                transform: rotate(180deg) !important;
              }
            }
          }

          p {
            margin-bottom: 0;
            color: #ffffff;
            font-size: 14px;
            text-align: left;
            padding: 14px;
            padding-top: 0;
            font-family: Poppins;
          }
        }
      }
    }
    .faqBottomHeaderLine {
      font-size: 28px;
      font-family: Poppins;
    }

    .faqBottomLine {
      font-size: 22px;
      font-family: Poppins;
      font-weight: 500;
    }
    .more-questions {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 80px;

      p {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 24px;
      }

      span {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
      }

      a {
        width: 222px;
        height: 64px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #fff;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        background: linear-gradient(91deg, #3caff5 -2.45%, #1a69a3 97.01%);
        text-align: center;
      }
    }
  }
}

@keyframes rotateBottomhalf {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(90deg);
  }
}
@keyframes slide-in-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* faq-section ends */

/* common styling starts */

.section-heading {
  margin: 32px 0;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.brand-secondary {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-family: Poppins;
  border-image-source: linear-gradient(to left, #1b9bdc, #a9e2ff, #1b9bdc);
  border-width: 1px;
  border-image-slice: 1;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: round;
}

.brand-secondary.lg {
  padding: 8px 40px;
}

.brand-primary {
  /* border: 1px solid #198bc7; */
  border-radius: 4px;
  background: #198bc7;
  color: #ffffff;
  font-family: Poppins;
  border-image-source: linear-gradient(to left, #1b9bdc, #a9e2ff, #1b9bdc);
  border-width: 1px;
  border-image-slice: 1;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: round;
}

.brand-primary.lg {
  padding: 8px 40px;
}

.brand-primary-gradient {
  border-radius: 0px !important;
  border: 1px solid #fff;
  background: linear-gradient(91deg, #3caff5 -2.45%, #1a69a3 97.01%);
  color: #fff;
  font-weight: 700;
  height: 60px;
  font-family: Poppins;

  &.lg-btn {
    font-size: 24px;
  }
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.website-text {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1.6px;
}

.custom-background {
  /* background: rgb(31, 32, 37); */
  background: linear-gradient(
    90deg,
    rgba(31, 32, 37, 1) 0%,
    rgba(2, 0, 36, 1) 35%,
    rgba(7, 68, 100, 1) 100%
  );
}

.inmageHome {
  height: 300px;
  margin: auto;
}

.inmage {
  margin-right: 20px;
  margin-block: auto;
}

.applpcardbuttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.needsContent {
  /* margin-right: 100px; */
  margin-top: -15px;
}

.intext {
  margin-left: -50px;
  width: 100%; /* width: 400px; */
  height: 100%;
}
.rounded-circle {
  align-self: center;
}

.text-center {
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
  font-size: 45px;
  font-weight: 600;
  line-height: normal;
  font-family: Poppins;
  width: 100%;
}

.main {
  padding: 6rem;
}

.productsDiv {
  padding: 10px;
}

/* .tnc {
  margin-left: 5%;
  width: 90%;
} */
.productHeader {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: normal;
  font-family: Poppins;
  margin: 20px;
}

@media only screen and (max-width: 767px) {
  .mainDiv {
    display: grid;
    justify-items: center;
  }

  .hero-heading {
    margin-top: 20px;
  }
  .text-center {
    font-size: 30px;
  }
  .inmage {
    padding: 5%;
  }
  .main {
    /* margin:-40px; */
    display: block;
    /* padding: 3rem; */
    width: 100%;
  }
  /* tnc {
    width: 100%;
    text-align: center;
    margin-left: 3%;
    padding-right: 5%; 
  } */
  /* .text-center{
   width:120%;
    text-align:-webkit-match-parent;
    padding-right: 30%;
  } */

  .img-fluid {
    align-self: center;
    max-width: 80%;
  }
  .feedback-ratings {
    padding: 0%;
  }
  .applpcardbuttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .intext {
    margin-left: 10px;
    width: 200px;
    text-align: center;
  }

  .application-process-section {
    .heading {
      margin-top: 30px;
      /* how it works */
    }
  }

  .a {
    margin-top: 430px;
    margin-left: -40px;
  }

  .about-us-container {
    & .content {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      .heading {
        padding-top: 10%;
        justify-content: baseline;
        opacity: 0.8;
        color: #1b9bdc;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 72px;
      }
    }
  }

  .application-process-card {
    /* align-content: center; */
    padding: 15px;
  }
  .lending {
    /* width: 400px; */
    display: flex;
    align-content: stretch;
    justify-content: space-evenly;
    margin-top: -30px;
  }

  .space {
    height: 20px;
  }

  .page-container {
    padding: 0 20px 60px;
    margin-top: -5px;
  }

  .application-process-card {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .user-feedback-section .feedback-card-container {
    flex-direction: column;
    align-items: center;
    padding-right: 10%;
    padding-left: 10%;
  }

  /* img{
    display: none;
  } */

  .page-container {
    padding: 0 20px 60px;

    &.products-page {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 86px;
    }
  }

  .App {
    overflow: hidden;
  }

  .products-hero-section {
    padding-bottom: 100px;
  }

  .description-section {
    margin-top: -20px;
    margin-bottom: 0px;
    display: block;
  }

  .btn {
    width: 98%;
    margin-bottom: 10px;
    /* margin-left:-15px */
  }

  /* .produc {
    gap: 0px
  } */

  .heading {
    padding: 0px;
  }

  .img-home {
    padding-bottom: 50px;
    margin-right: 20%;
  }
  .a {
    margin-top: 430px;
  }

  .brand-primary {
    margin-left: 3%;
    margin-right: 3%;
  }

  .brand-secondary {
    margin-left: 3%;
    margin-right: 3%;
  }

  .end {
    margin-left: -5px;
  }

  .hero-content {
    text-align: left;
  }

  .hero-content-button {
    width: 150%;
  }

  .hero-section .content h6 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(
      180deg,
      rgb(27, 155, 220) 0%,
      rgb(117.94, 208.84, 255) 54.51%,
      rgb(27, 155, 220) 100%
    );
    background-clip: text;
    color: transparent;
    font-size: 21px;
    font-weight: 800;
    /* font-size: 20px; */
    /* font-weight: 600; */
    margin-bottom: 1rem;
    line-height: normal;
    width: 300px;
    margin-top: 50px;
    /* font-family: "General Sans Variable"; */
  }
  .hero-section .content p {
    font-size: 18px;
    font-weight: 700;
    width: 300px;
  }

  p {
    margin-left: left;
  }

  .hero-section .content {
    width: 205px;
  }

  .application-process-card {
    display: flex;
    justify-content: space-between;
    padding: 60px 0 0px 80px;
    border-radius: 24px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    color: #ffffff;

    .content {
      width: 125%;
      align-self: center;
      margin-right: 80px;

      .list-number {
        display: flex;
        /* margin-top: 24px; */
        height: 46px;
        width: 46px;
        /* border-radius: 13px; */
        background: #0072ad;
        /* line-height: 28px; */
        align-items: center;
        justify-content: center;

        span {
          width: 36px;
          height: 36px;
          display: block;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 40px;
          font-size: 24px;
          justify-content: center;
          align-items: center;
        }
      }

      h6 {
        margin: 10px;
        padding: 15px;
        margin-top: 12px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 16px;
        font-family: Poppins;
      }

      p {
        font-size: 12px;
        margin-bottom: 0px;
        font-weight: 300;
        font-family: Poppins;
      }
    }
  }

  .btn-container {
    margin-left: -20px;
  }

  .application-process-section {
    grid-column-gap: 0px;
    column-gap: 0px;
    margin: 0px;

    /* .heading {
      &.extra-margin-top {
        margin-top: 24px;
      }
    } */
  }

  /* About Us */
  .aboutcard {
    /* margin-left: 2%;
    margin-right: 2%; */
    .content {
      padding-left: 3%;
      padding-right: 3%;

      /* padding-top: 4%; */
      h6 {
        font-size: 19px !important;
        font-weight: 700 !important;
        margin: 0px;
        padding: 0px;
      }
      p {
        font-size: 16px !important;
        padding: 0px !important;
      }
    }
  }

  .page-container {
    &.about-us {
      padding-top: 0px;
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }

  .about-us-container {
    & .about-points-container {
      & .each-point {
        & .application-process-card {
          & .content {
            p {
              padding: 0%;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .about-points-container {
    .each-point {
      .application-process-card {
        height: 210px !important;
      }
    }
  }

  .about-us-container {
    & .content {
      margin-left: 20px;

      .about-section {
        display: block;
        direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
      }
    }
  }
  .about-section-content {
    padding-right: 0% !important;
  }

  .products-hero-section,
  .responsible-lending-section,
  .contact-us-section,
  .faq-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: auto;

    h6,
    .heading {
      background: linear-gradient(
        91deg,
        #1b9bdc -2.45%,
        #76d1ff 51.77%,
        #1b9bdc 97.01%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-top: 25%;
    }

    .section-heading {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 64px;
      font-family: Poppins;
      margin-top: -80px;
    }
  }

  .hero-section {
    padding: 0px;
    margin: 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 30px;
  }

  .page-container {
    &.contact-us {
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 16px;
    }
  }

  .faq-section {
    & .faq-container {
      & .more-questions {
        p {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 24px;
        }

        span {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 24px;
        }
      }

      & .faq-tab-container {
        margin-top: -40px;
        .answer-section {
          padding-left: 6%;
          padding-right: 6%;
          justify-content: center;
          align-items: center;
        }

        & .tab-buttons {
          padding-left: 6%;
          padding-right: 6%;

          & button {
            &.active-btn {
              background: linear-gradient(
                91deg,
                #00246a -2.45%,
                #2199ef 97.01%
              );
            }
          }
        }
      }
    }
  }

  .page-container {
    &.faq {
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .contact-us-section {
    & .content {
      margin-top: 70px;
      p {
        margin-top: 0px;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        font-family: Poppins;
      }
    }
  }

  .responsible-lending-section {
    & .application-process-card {
      width: 350px;

      & .content {
        width: 320px;

        p {
          margin-top: 0;
          font-size: 14px;
          font-family: Poppins;
        }
      }
    }
  }

  .page-container {
    &.responsible-lending-page {
      padding: 0px;
      margin-top: 40px;
      text-align: center;
    }
  }

  .responsible-lending-section {
    .content {
      p {
        margin-top: 32px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        font-family: Poppins;
      }
    }
  }

  .application-process-card {
    display: block;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 24px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    color: #ffffff;

    .inmageHome {
      height: 150px;
    }

    .content {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0px;
      margin-bottom: 20px;

      h6 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 16px;
        font-family: Poppins;
      }

      p {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 300;
        font-family: Poppins;
        padding: 10px;
      }
    }
  }

  .application-process-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    .heading {
      font-family: Poppins;
      /* display: none; */
    }
  }

  .px-4 {
    margin-top: 20px;
  }

  .productsDiv {
    margin: 0px -20px 0px 0px;
    padding: 0px 20px;
    align-items: center;
    justify-content: center;
  }
}
.productsDivBtn {
  display: flex;
  justify-content: center;
}

.img-container {
  display: flex;
  justify-content: center;
}

h5 {
  margin-top: 12px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-left: 10px;
  font-family: Poppins;
  color: #ffffff;
}

h1 {
  color: #1b9bdc;
  text-align: center;
  margin-top: 12px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 16px;
  font-family: Poppins;
}

h2 {
  font-size: 20px;
  font-family: Poppins;
}

h3 {
  font-size: 20px;
}

@media only screen and (max-width: 1160px) {
  .products-hero-section,
  .responsible-lending-section,
  .contact-us-section,
  .faq-section {
    .btn {
      text-align: center;
    }
  }

  .page-container {
    &.about-us {
      padding-right: 60px;
      padding-left: 60px;
    }
  }

  .about-us-container {
    & .about-points-container {
      & .each-point {
        & .application-process-card {
          height: 200px !important;
          & .content {
            p {
              padding: 0%;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

td,
th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.aboutus-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  border-radius: 34px;
  background: linear-gradient(140deg, #5e7784 5.94%, #2a363b 90.73%);
  color: #ffffff;
  margin-bottom: 40px;
  width: 10px;
}

/* contact us renewd styles */

/* .page-container {
  display: flex;
  justify-content: center;
  align-items: center;
    flex-wrap: wrap;
} */

.mainDivAtContact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 30px 0px 0px -10px;
}

.mainHeaderatContactSection {
  background: linear-gradient(
    91deg,
    #1b9bdc -2.45%,
    #76d1ff 51.77%,
    #1b9bdc 97.01%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 34px;
  font-weight: 600;
  text-align: left;
  font-family: Poppins;
}

.subHeaderAtContactSection {
  text-align: left;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 300;
  font-family: Poppins;
  color: white;
}

.imageInContact {
  float: right;
  margin-left: 0px;
  height: 300px;
  /* max-width: 30%;
  width: 300px; */
}

.hangout {
  float: left;
  margin-left: 20px;
  max-width: 30%;
  margin-left: 0;
  height: 60px;
}

.textContent {
  max-width: 60%;
}

.form-container {
  max-width: 600px;
  align-items: left;
  margin-left: 10%;
}

.form-group {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.form-group:last-child {
  margin-bottom: 48px;
}

.form-control {
  padding: 10px;
  padding-left: 16px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.753);
  /* border-radius: 8px; */
  /* font-size: 18px; */
  height: 48px;
  /* border: 1px solid #fff; */
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.753);
}

.contactUsDropdown {
  width: 100%;
  padding: 10px;

  /* width:201px; */
  /* padding-left: 16px; */
  margin: 0px 26px 10px 0px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.753);
  border: 1px solid #fff;
  border-radius: 8px;
}

.contact-submit-button {
  padding: 8px 40px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Poppins;
}

.contact-submit-button:hover {
  background-color: #637f9e;
  opacity: 1;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.form-container,
.aboutus-card {
  flex: 1 1;
  min-width: 300px;
  margin: 10px;
}

.form-container .form-group {
  margin-bottom: 15px;
}

.form-container .form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.emailLink .contact-us-font {
  display: none;
  font-size: 16px;
  line-height: 1.5;
  margin-right: -100px;
}
.vl {
  border-left: 1px solid rgb(248, 248, 248);
  height: 270px;
}
.contactUsMain {
  text-align: left;
  margin-left: 4%;
  font-size: 2rem;
  margin-top: -10vw;
}

@media (max-width: 760px) {
  .contact-submit-button {
    margin-left: 30%;
  }
  .contactUsMain {
    text-align: center;
    margin-right: 7vw;
  }
  .contactUsDropdown {
    margin: 0px 0px 10px 0px;
  }
  .mainDivAtContact {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 60px;
  }

  .textContent {
    max-width: 100%;
  }

  .imageInContact {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .row-container {
    flex-direction: column;
    align-content: space-around;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
  }
  .emailLink {
    display: flex;
    margin-left: 16vw;
    margin-top: 10vh;
  }
  .vl {
    display: none;
  }
  /* .top-cards-container{
        flex-direction: column;
  }  */
}

.responseText {
  color: #ffffff;
  text-align: center;
}

/*scroll-bar stylings*/
::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  background-color: #2a363b;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.116);
  border-radius: 10px;
  border-left: 2px solid #2a363b;
  border-right: 2px solid #2a363b;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff86;
}

/*inline-stylings*/
.applicationProcessCard {
  font-size: 16px;
  font-weight: 300;
}
.dropdownUnderLine {
  margin: 0px;
}
.nav-logo-creditmitra {
  width: 180;
}
.headerMainDiv {
  display: flex;
}
.headerSecondDiv {
  color: white;
  display: flex;
}
.productDropDownBg {
  color: white;
}
.buttonInLocation {
  color: #fff;
  text-decoration: none;
}

.contactusCheckBox {
  color: #fff;
}
.contactusCheckBoxLegal {
  text-decoration: none;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contactusCheckBox {
  margin-left: 10px;
  font-size: 13px;
}

.contactusCheckBoxLegal {
  margin-left: 5px;
}


/* easy loan stylings */
.easyloan-container{
overflow: hidden;
/* padding-right: 20px;
padding-left: 20px; */
}
.easy-loan-main-logo{
  align-self: start;
  margin-top: 20px;
  margin-left: 20px;
}
.pInEasyLoan{
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 300;
  font-family: Poppins;
  color: #fff;
  width: 90%;
  margin: auto;
}
.top-cards-container {
  display: flex; 
  justify-content: center;
}

.top-cards {
  text-align: center;
  border: 1px solid #00000061;
  border-radius: 10px;
  /* background:linear-gradient(140deg, #59666e 5.94%, #a0b3bd3b 90.73%); */
  background-color: #4c606bac;
  box-shadow: 10px 20px 50px 10px rgb(0 0 0);
  margin: 10px;
}
.bottom-cards{
  text-align: center;
  border: 1px solid #00000061;
  border-radius: 10px;
  background:linear-gradient(140deg, #5e7784 5.94%, #151b1e3b 90.73%);
  box-shadow: 10px 20px 50px 10px rgb(0 0 0);
  margin: 10px;
}
.top-card-text{
  color: #fff;
  font-size: 10px;
  font-family: Poppins;
  margin: 10px;
}
.top-card-img{
  height: 50px;
  margin: 10px;
}
.line {
  border-left: 1px solid rgb(248, 248, 248);
  height: 40px;
}
.banner-container{
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e3b 90.73%);
  padding: 50px;
  flex-wrap: wrap
}
.banner-elements{
  display: flex;
  align-items: center;
  padding: 20px;
}
.category-container {
  width: 100%; 
}
.fieldName{
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  width: 100%;
  margin: 5px;
}
.easyloan-form-container{
  display: flex;
  justify-content: center;
}
.el-input{
  display: inline;
  font-size: 12px;
  height: 35px;;
}

  .list-row-el {
    display: flex;
    flex-wrap: wrap;
    justify-content:center
}
.hiring-button-el{
  background: transparent;
  transition: #1da1e8 0.3s ease;
  color: #ffffff;
  border: 1px solid #7ED2FF;
  margin: 5px;
  border-radius: 17px;
}

/* .hiring-button-el.selected {
  background-color: rgb(75, 75, 144);
} */
.el-button{
  display: flex;
  justify-content: center
}

.el-form{
  border: 1px solid #151b1e;
  padding: 60px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e3b 90.73%);
  border-radius: 20px;
  box-shadow: 10px 20px 50px 10px rgb(0 0 0);
}

/* Navbar styles */
/* CM Logo */


.navbar-brand {
  margin-left: 2%;
  margin-right: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}

.nav-btn {
  margin-left: 0% !important;
  margin-right: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

.nav-link:hover,
.nav-link.active {
  color: #53b9eb !important;
}

.dropdown-toggle {
  color: #00246a;
}
.dropdown-menu {
  /* background:  #1B9BDC ; */
  color: white;
}

.dropdown-menu a:hover {
  color: #1b9bdc;
}

.nav-link {
  /* margin-left: 10px; */
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.nav-item-seperator {
  display: none;
}

.header-nav a {
  font-size: 16px;
  color: #ffffff;
  font-family: Poppins;
}

.nav-item {
  font-size: 16px;
  color: #ffffff;
  font-family: Poppins;
  grid-gap: 40px;
  gap: 40px;
  color: #1b9bdc;
  /* margin-left: 60px; */
  /* font-weight: 600; */
}
.nav-item .dropdown {
  font-size: 16px;
  color: #ffffff;
  font-family: Poppins;
  grid-gap: 40px;
  gap: 40px;
  color: #1b9bdc;
  margin-left: 50px;

  margin-left: 0px;
}

/* .mr-auto {
} */

.dropdown-item {
  font-family: Poppins;
  /* color:#0072AD; */
}
/*fixed navbar*/
.navbar {
  /* display: -moz-inline-box; */
  position: fixed;
  top: 0;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.8);  */
  transition: background 0.3s ease;
  z-index: 1000;
  height: 60px;
  display: flex; 
  padding-left: 1%; 
  padding-right:1%
}

.navbar .navbar-toggler:focus,
.navbar .navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.navbar-nav {
    width: 100%;
    justify-content: space-around;
}

.header-nav .navbar-nav {
  /* gap: 40px; */
  .brand-seconday {
    margin: 0px;
  }
}

.header-nav a.active {
  color: #1b9bdc;
  font-weight: 600;
}
.CallImg{
  height: 20px;
  width: 20px;
  margin-right: 1vw;
}
.info{
  margin-top: -20px;
  font-size: 10px;
  justify-content: space-around;
  font-family: Poppins;
  text-align: right;
  font-weight:500;
  /* margin-top: 0px;ss  */
}
/* .info:hover{
  color:#8159ff;
} */
.timeStamp{
  font-size: 9px;
  font-family: Poppins;
  font-weight:300;
}
/* hamburger kicks in */
@media (max-width: 991px) {
  .navbar-container {
    /* background-color: #172631; */
    background: linear-gradient(
      90deg,
      rgba(31, 32, 37, 1) 0%,
      rgba(2, 0, 36, 1) 35%,
      rgba(7, 68, 100, 1) 100%
    );
  }

  .nav-item-container {
    font-size: 1.23rem;
    margin-left: 4.5%;
  }

  .nav-item-seperator {
    display: block;
    margin-left: 4.5%;
    margin-right: 4.5%;
  }

  .nav-btn {
    margin-top: 2% !important;
    margin-bottom: 1% !important;
    align-self: center;
    width:91%;
  }


}

/* phone view */
@media only screen and (max-width: 767px) {
  .info{text-align: center;margin-top: -10px;}
  .header-nav a {
    font-size: 14px;
  }
}

/*styling is not working ,we have to media query*/
/* @media only screen and (max-width: 1279px) {
  .nav-item {
    margin-left: 40px;
  }
}
@media only screen and (max-width: 1219px) {
  .nav-item {
    margin-left: 35px;
  }
}
@media only screen and (max-width: 1190px) {
  .nav-item {
    margin-left: 30px;
  }
} */

@media only screen and (max-width: 1160px) {
  /* .nav-item {
      margin-left: 25px;
    }
    @media only screen and (max-width: 1130px) {
      .nav-item {
        margin-left: 20px;
      }
    } */
  /* @media only screen and (max-width: 1100px) {
      .nav-item {
        margin-left: 15px;
      }
    }
    @media only screen and (max-width: 1070px) {
      .nav-item {
        margin-left: 10px;
      }
    }
    @media only screen and (max-width: 1040px) {
      .nav-item {
        margin-left: 5px;
      }
    }
    @media only screen and (max-width: 1010px) {
      .nav-item {
        margin-left: 0px;
      }
    } */
}

/* Logic to make Social Components Desktop Work */
@media (max-width: 576px) { 
    .social-components-desktop {
        display: none;
    }
}

/* Logic to make Social components mobile work */
.social-components-mobile {
    display: none;
}

@media (max-width: 576px) { /* adjust the value as needed */
    .social-components-mobile {
        display: block;
    }
}

.Location-section {
  padding-bottom: 50px;
  text-align: center;

  small {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 44px;
    display: block;
    font-family: Poppins;
  }

  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
    font-family: Poppins;
  }
}
.header {
  background: linear-gradient(
    91deg,
    #1b9bdc -2.45%,
    #76d1ff 51.77%,
    #1b9bdc 97.01%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  font-family: Poppins;
  /* margin: 50px; */
}

/* chart-cards */
.chart-cards {
  flex-direction: column;
  align-items: center;
  padding-right: 10%;
  padding-left: 10%;
  .card-container {
    display: flex;
    grid-gap: 32px;
    gap: 32px;
    align-items: center;
    justify-content: center;

    .each-card {
      padding: 10px;
      border-radius: 30px;
      align-items: center;
      background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
      .card-body {
        padding: 0;
        text-align: center;
        .comment {
          color: #fff;
          font-size: 16px;
          margin-bottom: 48px;
          width: 100%;
          padding-top: 20px;
        }
        .user-name {
          color: rgba(255, 255, 255, 0.6);
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
  }
}
.inner-card {
  border-radius: 18px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
  color: #ffffff;
  font-family: Poppins;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 400;
  height: 50px;
  font-family: Poppins;
  font-size: 20px;
  padding-top: 10px;
  /* padding: 8px 40px; */
}

select {
  padding: 10px;
  margin: 10px;
  font-size: 16px;
}

label {
  margin-right: 10px;
}
/* .dropdown-item{
    background-color:blue;
}

.dropdown-menu{
    padding: 0px;
}
*/
/* .dropdown-toggleFooter {
    color: #00246a;
  } */

.dropdown-toggleFooter {
  color: #fff;
  /* white-space: pre-line; */
  margin-left: -13px;
}
@media (max-width: 1300px) {
  .dropdown-toggleFooter {
    white-space: pre-line;
  }
}
@media (max-width: 575px) {
  .dropdown-toggleFooter {
    white-space: pre;
    /* margin-left: -17%; */
    text-align: left;
  }
}

.dropdown-menu a {
  /* background:  #1B9BDC ; */
  /* color: #ffffff; */
}

.dropdown-menu a:hover {
  color: #53b9eb;
}
.custom-dropdown-menu {
  color: #151b1e;
  background-color: #3d4f74 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.dropdownFooter-item {
  color: #151b1e;
}

.custom-dropdown-item {
  padding: 4px 8px !important;
}

.custom-dropdown-item .dropdown-item {
  padding: 4px 8px !important;
}
.sub-title {
  padding-top: 80px;
}
h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 44px;
  font-family: Poppins;
  color: #fff;
}

.processCard {
  margin: 20px;
}
/* .four-card-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    .each-card {
        display: flex;
        flex-direction: column;
      text-align: center;
      padding: 10px;
      border-radius: 30px;
      align-items: center;
      background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
      height: 200px;
      width: 200px;
    }
    .data{
        width: min-content;
        padding: 10px;
    }
} */
.card-container,
.four-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.card,
.processCard {
  /* background: #fff; */
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

/* bad style here */
.card-body,
.each-card {
  padding: 20px;
  /* background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%); */
}

.header,
.sub-title h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-family: Poppins;
}

/* .description-section .content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  } */

/* .description-section .img-container {
      text-align: center;
    }
    
    .description-section img {
      max-width: 100%;
      height: auto;
    }
    */
.product-apply-button {
  margin-top: 40px;
}

.headline {
  color: #3099ca;
  margin-top: 30px;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 16px;
  font-family: Poppins;
}

.gplay {
  width: 40%;
}

/* General styles for the card */
.card-container,
.four-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.each-card {
  flex: 1 1 30%;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
}

@media (max-width: 768px) {
  .each-card {
    flex: 1 1 45%;
  }
}

@media (max-width: 480px) {
  .each-card {
    flex: 1 1 100%;
  }
}

/* Additional styling for other elements */
/* .page-container {
    padding: 20px;
  }
  .header, .sub-title h2 {
    text-align: center;
    margin: 20px 0;
  } */
/* .description-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
        flex-direction: column;
        text-align: center;
     
  } */
/* .description-section .content {
    margin-bottom: 20px;
  } */
/* 
  .img-container img {
    max-width: 100%;
  } */
.product-apply-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.footer-note {
  margin-top: 10px;
  color: #fff;
  font-size: 18px;
  margin-bottom: 0;
  font-family: Poppins;
  text-align: center;
}

/* footer-section starts */
.footer-section {
  padding: 154px 20px 40px;
  color: #ffffff;
  position: relative;
  z-index: 999;

  .footer-globe {
    position: absolute;
    height: 450px;
    z-index: 400;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .footer-data {
    background-color: red;
  }

  .footer-content {
    z-index: 1000;
    border-radius: 30px;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(150px);
            backdrop-filter: blur(150px);
    padding: 72px 32px 24px;
    position: relative;

    .footer-container {
      display: flex;
      grid-gap: 0px;
      gap: 0px;

      .navigation-container {
        display: flex;
        flex-wrap: wrap;
        /* grid-gap:0%; */
        justify-content: space-between;
        /* margin-top: 15px; */

        h6 {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px;
          width: auto;
          padding-top: 15px;
          font-family: Poppins;
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            margin-bottom: 18px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: #fff !important;
              font-size: 16px;
              text-decoration: none;
              font-family: Poppins;

              /* &:hover {
                } */
            }
          }
        }
      }
    }

    .copyright-text {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      margin-bottom: 0;
      text-align: center;
      margin-top:12px;
      font-family: Poppins;
    }
  }
}
.pinFooter{
  font-family: Poppins;
}

/* footer-section ends */

@media (max-width: 760px) {
  .footer-globe {
    height: 300px !important;
    top: 45px !important;
    /* margin-top: 10%;
    width: 80%; */
  }

  .pinFooter {
    margin-left: 0px;
  }

  .footer-content {
    flex-direction: column;
    font-size: 14px;
  }

  .footer-content .navigation-container {
    flex-direction: column;
  }
}

@media (min-width: 769px) and (max-width: 1160px) {
  .footer-content {
    flex-direction: column;
    font-size: 14px;
  }

  .footer-content .navigation-container {
    flex-direction: column;
  }
}



.h5 {
  font-size: 12px;
  display: none;
}
.heading {
  background: linear-gradient(
    91deg,
    #1b9bdc -2.45%,
    #76d1ff 51.77%,
    #1b9bdc 97.01%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  font-family: Poppins;
}

/* Featured Blogs Styling */
.feature-card {
  /* border: 1px solid hsla(0, 0%, 80%, 50%);
    /* box-shadow: 5px 5px 5px hsla(0, 0%, 0%, 0.102); */
  /* border-radius: 20px; */
  text-align: left;
  max-width: 392px;
  display: inline-block;
  margin: 10px;
}

.blog-feature-image {
  width:-webkit-fill-available;
  z-index: -10;
  /* border-top-left-radius: 19px; */
  /* border-top-right-radius: 19px;  */
}

.blog-feature-date {
  margin-top: 12px;
  font-size: 16px;
  margin-bottom: 16px;
  font-family: Poppins;
  margin-left: 10px;
  color: #ffffff;
}

.blog-feature-summary {
  margin-top: 12px;
  font-size: 18px;
  margin-bottom: 16px;
  font-family: Poppins;
  margin-left: 10px;
  color: #ffffff;
}

.blog-feature-readmore {
  margin-top: 12px;
  font-size: 18px;
  margin-bottom: 16px;
  font-family: Poppins;
  margin-left: 10px;
  color: #ffffff;
}

.blogs-section .content h6 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-weight: 600;
  line-height: normal;
  width: 300px;
  margin-top: 12px;
  font-size: 32px;
  margin-bottom: 16px;
  font-family: Poppins;
  align-items: center;
  text-align: center;
}

.blog-feature-title {
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
  font-size: 20px;
  font-family: Poppins;
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 400px) {
  /* making blogs responsive for small mobile screens */
  .feature-card {
    width: 95vw;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .featured-blog-container {
    margin: 0px;
    padding: 0px;
  }
}
/* End of featured blogs styling */

/* Blog Content Starts */
.blog-content-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-content-paragraph {
  color: white;
}

.blog-content-date {
  color: white;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 4%;
  font-size: 1.2rem;
  font-weight: 500;
}

.blog-image-container {
  /* border-radius: 2%; */
  width: 70vw;
  max-width: 100%; /* to ensure the image doesn't overflow its container */
  /* height: 30vw;  */
}

.blog-content-text {
  margin-top: 5%; /* adjust this value as needed */
  width: 83vw;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: white;
  text-align: left;
}

.blog-content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-content-heading {
  background: linear-gradient(
    91deg,
    #1b9bdc -2.45%,
    #76d1ff 51.77%,
    #1b9bdc 97.01%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.9rem;
  font-weight: 700;
  text-align: center;
  font-family: Poppins;
  margin-top: 100px;
  margin-bottom: 7%;
}

.feature-blog-link {
  text-decoration: none;
}

.feature-blog-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .blog-content-heading {
    font-size: 2.3rem;
  }

  .blog-content-date {
    font-size: 1.4rem;
  }

  .blog-content-text {
    font-size: 1.2rem;
    width: 90vw;
  }

  .blog-content-text p {
    margin-left: 0;
  }

  .blog-image-container {
    width: 90vw;
    margin-left: 0px;
  }
}

@media screen and (max-width: 915px) and (min-width: 769px) {
  .blog-content-heading {
    font-size: 2.8rem;
  }

  .blog-content-date {
    font-size: 1.6rem;
  }

  .blog-content-text {
    font-size: 1.3rem;
    width: 80vw;
  }

  .blog-image-container {
    width: 80vw;
  }
}

.credit-score-tips {
        /* padding: 20px; */
    /* max-width: 800px; */
    margin: auto;
    /* border-radius: 8px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
   }
  
  .credit-score-tips h1 {
    margin-bottom: 20px;
  }
  
  .credit-score-tips p {
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .credit-score-tips table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .credit-score-tips table th,
  .credit-score-tips table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .credit-score-tips table th {
  }
  
  .credit-score-tips ul {
    list-style-type: none;
    padding: 0;
  }
  
  .credit-score-tips ul li {
    margin-bottom: 10px;
    padding: 10px;
    /* border-left: 5px solid #2196f3; */
  }
  
  .credit-score-tips ul li strong {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
/* Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgb(255 255 255) ;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Number ke styles */

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}

.uniform-width {
  width: 100px; /* Set your desired width */
}

.border-subtle-blue {
  border: 1px solid #7ec5e8;
  color: rgb(27, 155, 220);
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f0f8ff;
}

.border-subtle-blue:focus {
  outline: none; /* Removes the default focus outline */
  border-color: #55c6ff; /* Changes the border color on focus */
  box-shadow: 0 0 5px rgba(27, 155, 220, 0.5); /* Adds a subtle glow effect */
}

/* Styles End */

/* infographic start */
/* .info-trigger {
  cursor: pointer;
  margin-left: 5px;
} */

/* .loan-infographic {
  display: none;
  position: absolute;
  width: 200px; 
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 100; 
} */

/* .info-trigger:hover + .loan-infographic {
  display: block;
} */
/* infographic end */

h3 {
  margin-top: 60px;
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: normal;
  text-align: center;
  font-family: Poppins;
}

.page-container.calculator {
  padding: 200px;
}

.row.main-slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-range-slider {
  margin-top: 0px;
  width: 100%;
  -webkit-appearance: none;

  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.form-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #199cdb;
  cursor: pointer;
}

.form-range-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #199cdb;
  cursor: pointer;
}

.mt-7 {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.753);
  border: 1px solid #fff;
  width: 10vw;
  height: 5vh;
  margin-left: 14vw;
}

.mb-4 {
  margin: 20px;
}

.slider-container {
  padding: 0 15px;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: #f9f9f9;
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 79%;
  justify-content: space-evenly;
}

.input-section {
  margin-bottom: 3% !important;
}

.text-and-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.headers {
  color: #1b9bdc;
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.input-wrapper::after {
  content: "%";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #199cdb;
}

.input-wrapper-principle {
  position: relative;
  display: inline-block;
}
.input-wrapper-principle::after {
  content: "₹";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #199cdb;
}
.form-cc {
  padding-right: 10px; /* Adjust to make space for the symbol */
}
.applyNowContainerMobile {
  display: none;
}

.applyNow {
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  width: 60%;
}

.applyNow:hover {
  background-color: #0056b3;
}

.pie-chart-container {
  margin-top: 4.8%;
  margin-bottom: 8%;
  overflow: visible;
}

.pie {
  width: 45%;
  overflow: visible;
}

.calculator-card {
  border-radius: 32px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 1%;
  padding-bottom: 0.7%;
  border: 1px solid #3caff5;
  /* margin: 50px;
    padding: 150px 50px; */
}

.applyNow {
  border-radius: 0px !important;
  border: 1px solid #fff;
  background: linear-gradient(91deg, #3caff5 -2.45%, #1a69a3 97.01%);
  color: #fff;
  /* font-weight: 700; */
  border-image-source: linear-gradient(to left, #1b9bdc, #a9e2ff, #1b9bdc);
  border-width: 1px;
  border-image-slice: 1;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: round;
  font-size: 18px;
  font-family: Poppins;
  padding: 6px;
  /* margin: 30%; */
  /* margin-left:230px */
}

.text-header {
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
  font-size: 45px;
  font-weight: 600;
  line-height: normal;
  font-family: Poppins;
  width: 100%;
  text-align: left;
}

.emi-details-container{
  
}

.colors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3%;
  padding-bottom: 3%;

  height: 10px;
  font-size: 16px;
  /* margin: 20px; */
  color: #fff;
  font-weight: 400;
  .legendText {
    font-size: 1rem;
    display: inline-flex;
    /* transform: translateY(-40%); */
  }
}

.lightBlue {
  border-left: 10px solid #199cdb;
  height: 10px;
  font-size: 18px;
  /* margin: 10px 10px; */
  margin-right: 10px;

  color: #fff;
  font-weight: 400;
  display: inline-flex;
}

.whiteBox {
  border-left: 10px solid #ffff;
  height: 10px;
  font-size: 18px;
  /* margin: 10px 10px; */
  margin-right: 10px;
  color: #fff;
  font-weight: 400;
  display: inline-flex;
}

.purpleBox {
  border-left: 10px solid #6610f2;
  height: 10px;
  font-size: 18px;
  margin: 10px 10px;
  color: #fff;
  font-weight: 400;
}
.interestAndPay {
  display: flex;
  justify-content: center;
}
.vlCalci {
  /* border-left: 2px solid #052c65; */
  font-size: 18px;
  margin: auto;
}
.aboutEmi {
  text-align: justify;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 300;
  color: #fff;
  font-family: Poppins;
}
.EmiElements {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 300;
  color: #fff;
  font-family: Poppins;
}
.emi-details {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  margin-top: 30px;
  font-size: 1.4rem;
  padding-left: 2%;
  padding-right: 2%;
}

.emi-left {
  flex: 6.5 1;
  text-align: left;
  /* Additional styles for left column if needed */
}

.emi-right {
  flex: 2.3 1;
  text-align: left;
  color: #fff;
  /* Additional styles for right column if needed */
}

.glow {
  box-shadow: 0 0 13px rgba(79, 255, 255, 0.44); /* Example glow effect */
  transition: box-shadow 0.5s ease-in-out;
}

@media (min-width: 768px) and (max-width: 1124px) {
  .emi-details {
    span {
      font-size: 1.05rem;
    }
    .emi-left {
      flex: 4.4 1;
    }
    .emi-right {
      flex: 2 1;
    }
  }
  .colors {
    .legendText {
      font-size: 0.99rem;
      .legendValue {
        display: none;
      }
    }
  }
  .aboutEmi {
    text-align: justify;
  }

  .input-section {
    margin-bottom: 3% !important;
    margin-top: 3%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .pie {
    width: 60%;
  }
  .caclulator-card {
    padding-bottom: 1%;
  }
}

@media (max-width: 768px) {
  .loan-calc-heading {
    font-size: 2.1rem;
  }
  .calculator-card {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
  }
  .colors {
    display: none;
  }
  .colors {
    justify-content: center;
  }
  .output-container {
    h5 {
      margin-left: 0px;
    }
    margin-bottom: 5%;
  }
  .slider-container {
    padding: 0px;
    height: 90%;
    .headers {
      font-size: 1rem;
    }
    .form-range-slider {
      width: 100%;
    }
  }

  .form-cc {
    width: 22vw;
    font-size: 16px;
  }
  .pie {
    height: 30vh;
    width: 60vw;
    display: none;
  }

  .emi-details {
    padding-left: 5%;
    padding-right: 5%;
    span {
      font-size: 1rem;
    }
    .emi-left {
      flex: 6 1;
    }
    .emi-right {
      flex: 5 1;
      text-align: right;
    }
  }
  .legendText {
    font-size: 1rem;
  }

  .applyNowContainerLaptop {
    display: none !important;
  }
  .applyNowContainerMobile {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .aboutEmi {
    padding-left: 2.8%;
    padding-right: 2.8%;
  }
  .col-sm-5 {
    display: block !important;
    width: 100%; /* Makes the element take the full width */
  }
  .col-sm-6 {
    display: block !important;
    width: 100%; /* Makes the element take the full width */
  }
  .pie-chart-container {
    margin: 0px;
  }
}
/* phones only */
@media (max-width: 421px) {
  .border-subtle-blue {
    padding: 0px 5px;
  }
  .input-wrapper-principle::after {
    font-size: 13px;
    right: 2px;
    top: 50%;
    transform: translateY(-43%);
  }

  .input-wrapper::after {
    font-size: 13px;
    right: 2px;
    top: 50%;
    transform: translateY(-43%);
  }
  .form-cc {
    font-size: 13px;
  }
}

.emi-table-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.emi-table-title {
  font-size: 2.3rem;
  font-weight: 600;
}
.emi-table {
  width: 100%;
  border-color: lightblue;
}

.emi-table-th {
  padding: 10px;
  border: 1px solid lightblue;
  background-color: #199cdb;
  text-align: center !important;
  font-size: 1.5rem;
}

.emi-table-td {
  padding: 10px;
  border: 1px solid lightblue;
  text-align: center !important;
  font-size: 1.2rem;
}

.view-more-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure the container has a height */
  margin-top: 1%;
}

.view-more {
  display: block; /* Make it a block-level element if it's not already */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  text-decoration: underline;
  background-color: transparent !important;
  color: white;
}
.view-more:hover {
  color: #199cdb;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .emi-table-title {
    font-size: 1.8rem;
    font-weight: 550;
  }
  .emi-table-th {
    font-size: 0.95rem;
  }
  .emi-table-td {
    font-size: 0.8rem;
  }

  .view-more {
    font-size: 0.85rem;
    padding: 5px 10px;
  }

  .emi-table {
    width: 90%;
    margin-left: 4%;
    margin-right: 4%;
  }
  .emi-table-td-closing {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .emi-table-title {
    font-size: 1.6rem;
    font-weight: 540;
  }
  .emi-table-th {
    font-size: 0.8rem;
  }
  .emi-table-td {
    font-size: 0.8rem;
  }

  .view-more {
    font-size: 0.82rem;
    padding: 5px 10px;
  }

  .emi-table {
    width: 90%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .emi-table-td-closing {
    display: none;
  }

  .emi-table-td-month {
    padding-left: 0%;
    padding-right: 0%;
  }
}

/* Container Styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Heading Styles */
.content-heading {
    background: linear-gradient(
        91deg,
        #1b9bdc -2.45%,
        #76d1ff 51.77%,
        #1b9bdc 97.01%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    font-family: Poppins, sans-serif;
    margin-top: 50px;
}

.subHeading {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 1.2rem;
    color: #ffffff;
    width: 80%;
    margin: 20px 0;
}

.hiring-heading {
    font-size: 2rem;
    margin: 20px 0;
}

/* Button Styles */
.hiring-button {
    background: transparent;
    transition: background-color 0.3s ease;
    color: #ffffff;
    border: 1px solid #7ED2FF;
    margin: 6px;
    border-radius: 17px;
    padding: 5px 10px;
}

.hiring-button.selected {
    background-color: blue;
}

.list-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Job Posts Styles */
.job-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
}

.job-card {
    background-color:transparent;
    max-width: 100vw;
    transition: transform 0.2s;
    width: 60vw;
}
.job-card:hover {
    transform: translateY(-10px);
}

.designation {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.head-line {
    color: #f8f9fa;
    font-size: 1rem;
    margin-bottom: 15px;
    font-family: Poppins;
}

.modes {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
}

.mode {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #7ED2FF;
    border-radius: 15px;
    font-size: 0.9rem;
    padding: 5px 10px;
}

hr {
    border: 1px solid #7ED2FF;
    width: 100%;
}

.like-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
align-items: flex-start;
    margin-top: -40px;
}

.like {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.like-text {
    font-size: 1rem;
    color: #ffffff;
}
.hiring-apply-now{
   border: 1px solid #a9e2ff;
   border-radius: 4px;
   background: #198bc7;
   color: #ffffff;
   font-size: 1rem;
   font-family: Poppins;
   padding: 4px 20px;
   margin-bottom: 20px;
}

@media(max-width:768px) {
    .like-container {
        margin-top:0px;
    }
}

.JD-container{
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 100px
}
h2{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: Poppins;
    color: #fff;
}
.JobDescription{
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: Poppins;
    color: #fff;
}
.location{
    color: #ffffff;
    font-size: 1rem;
    font-weight:light;
    margin-bottom: 10px;
    text-align: center;
    font-family: Poppins;
}
.card{
    -webkit-background-clip: text;
            background-clip: text;
}


/*MODAL stylings*/
.text-field{
    color: #ffffff;
    font-size: 1rem;
    font-weight:light;
    font-family: Poppins;
    margin: 10px  
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }
  
  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    border: 3px solid #198bc7;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 10px;
    outline: none;
    box-shadow:0 4px 50px ALICEBLUE;
    width:-webkit-fill-available;
    overflow: hidden;
  }
  textarea.form-control {
    resize: none;
    height: 100px;
  }
  .fileInput{
    color: #fff;
    width: 40%;
  }
  #pdfInput{
    color: #fff;
  }
  .form-in-hiring{
    margin-top: 10px;
  }
  .ModalContainer{
    display: flex;
    justify-content: space-around;
  }


.job-application-container{

}

.card-img-top{
    height: 16vh;
    width: 15vw;
}
.section-red {
    display: flex;
    background: repeating-radial-gradient(circle, rgb(92, 95, 105) 0%,
    rgba(2, 0, 36, 1) 30%,
    rgb(48, 109, 142) 100%);
    padding: 20px;
  }



  /* App.css or Background.css */

.background {
  background: linear-gradient(to bottom right, #0D47A1, #000000, #000000, #d455d0);
  height: 100vh;
  position: relative;
  overflow: hidden; 
}
.dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
  radial-gradient(circle , rgb(0, 0, 0) 5px, transparent 1px);
  background-position: 0 0, 100% 0, 0 100%, 100% 100%;
  background-size: 50px 50px;
}





  .section-blue {
    display: flex;
      background: repeating-radial-gradient(circle, rgb(92, 95, 105) 0%,
      rgba(2, 0, 36, 1) 100%,
      rgb(48, 109, 142) 10%);
          padding: 20px;
  }
  
  .section-green {
    display: flex;
    background: repeating-radial-gradient(circle, rgb(92, 95, 105) 0%,
    rgba(2, 0, 36, 1) 30%,
    rgb(48, 109, 142) 100%);
        padding: 20px;
  }
  .bubble {
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .process-card {
    flex-grow: 1;
    border: 1px solid;
  }

  .card-title{
    margin:10px
  }
  
  @media (max-width: 768px) {
    .bubble, .process-card {
      width: 100%;
    }
  }
  


  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  .auto-type-text {
    overflow: hidden; 
    border-right: .15em solid #5e7784;
    white-space: nowrap; 
    margin: 0 auto;
    animation: typing 3s steps(40, end), blink-caret .75s step-end infinite;
  }
  .align-items-center{
    display: flex;
    width: 80%;
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: rgb(172, 179, 183) }
  }
  .process-card{
  display: flex;
  flex-direction: column;
  padding: 0px 10px 20px 20px;
  border-radius: 24px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
  color: #ffffff;
  }
  .justify-content-around{
    display:flex;
  }


  /* feedback */
  /* FeedbackList.css */

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.feedback-card {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  animation: slideIn 1s ease-in-out, bounce 2s infinite ease-in-out;
}

.feedback-card img {
  border-radius: 50%;
  margin-right: 20px;
  width: 60px;
  height: 60px;
}

.feedback-card div {
  display: flex;
  flex-direction: column;
}

.feedback-card h4 {
  margin: 0;
  font-size: 1.2em;
}

.feedback-card p {
  margin: 5px 0 0;
}


/* FooterAnimations.css */

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.footer-globe {
  animation: fadeIn 1s ease-in-out;
}

.footer-content {
  animation: fadeIn 1.5s ease-in-out;
}

.dropdownFooter-item {
  animation: popIn 0.5s ease-in-out;
}
.footer-section-JA {
  padding: 154px 20px 40px;
  color: #ffffff;
  position: relative;
  z-index: 999;

  .footer-globe {
    position: absolute;
    height: 450px;
    z-index: 400;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .footer-data {
    background-color: red;
  }

  .footer-content-JA {
    z-index: 1000;
    /* background: rgba(255, 255, 255, 0.1); */
    -webkit-backdrop-filter: blur(70px);
    backdrop-filter: blur(150px);
    padding: 72px 32px 24px;
    position: relative;

    .footer-container {
      display: flex;
      grid-gap: 0px;
      gap: 0px;

      .navigation-container {
        display: flex;
        flex-wrap: wrap;
        /* grid-gap:0%; */
        justify-content: space-between;
        /* margin-top: 15px; */

        h6 {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px;
          width: auto;
          padding-top: 15px;
          font-family: Poppins;
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            margin-bottom: 18px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: #fff !important;
              font-size: 16px;
              text-decoration: none;
              font-family: Poppins;

              /* &:hover {
                } */
            }
          }
        }
      }
    }

    .copyright-text {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      margin-bottom: 0;
      text-align: center;
      margin-top:12px;
      font-family: Poppins;
    }
  }
}
.pinFooter{
  font-family: Poppins;
}
.fixed-banner {
    position: fixed;
    bottom: -110px;
    left: 0;
    width: 100%;
    background-color:  #dfe2e4;
    color: white;
    text-align: center;
    padding: 0px 0;
    transition: bottom 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .fixed-banner.visible {
    bottom: 0;
  }
  
  .fixed-banner .btn {
    color: #fff;
    text-decoration: none;
  }
  .banner-button{
    margin-left:20px;
  }
  @media (max-width:768px){
    .bannerText{
      font-size: 0px;
      /* margin-bottom: 4px; */
    }
    .banner-button {
      margin-left: 0px;
  } 
  }
  
.page404-container {
  min-height: 100vh;
}

.page404 {
  margin-top: 90px;
}

.gif404-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
}

.gif404 {
  width: 40%;
}

.para404 {
  font-size: 1.5rem;
  text-align: center;
  color: azure;
  font-family: Poppins, sans-serif;
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
}

.bottomPara404 {
  border-bottom: 2px solid rgba(25, 156, 219, 0.5);
  display: inline-block;
  padding-bottom: 5px;
}

.links404 {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  color: azure;
  .space404 {
    margin-left: 1%;
    margin-right: 1%;
  }
}

.links404 a {
  /* Targeting <a> tags directly for react-router-dom Link components */
  text-decoration: none; /* Removes the underline */
  color: #199cdb;
  /* Ensures the link color matches the parent if needed */
}

@media screen and (max-width: 768px) {
  .content404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .gif404 {
      width: 80%;
    }
  }
  .para404 {
    font-size: 1.2rem;
  }

  .links404 {
    font-size: 1.1rem;
    padding-left: 1%;
    padding-right: 1%;
  }
}

.pan-table-container {
    overflow-x: auto;
    margin: 0 auto; 
    padding: 50px; 
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse; 
    scroll-behavior: auto;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .data-table th {
    background-color: #f4f4f4;
  }
  
  .data-table td {
    color: white;
  }
  
  @media (max-width: 768px) {
    .data-table th, .data-table td {
      font-size: 12px;
      padding: 6px; 
    }
  }

  .pan-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pan-input-container{
display: flex;
flex-direction: column
  }
  .spinner{
color:aliceblue;
font-family: Poppins;
font-size: 1.5rem
  }
  .error{
    color: red;
    text-align: center;
  }



  .pan-table-container {
    width: 100%;
    max-width: 100%;
  }
  
  .scrollable-table {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
  
  .data-table th,
  .data-table td {
    padding: 8px 12px;
    text-align: left;
    white-space: nowrap; 
    color: white;
  }
  
  .data-table th {
    background-color: #333; 
  }
  
  .data-table tr:nth-child(even) {
    background-color: #444; 
  }
  
  .data-table tr:nth-child(odd) {
    background-color: #555; 
  }
  
